@tailwind base;
@tailwind components;
@tailwind utilities;

.form-label{
  @apply font-medium block mb-1 text-sm;
}
.form-input{
  @apply border rounded shadow-sm w-full py-1.5 px-3 text-sm text-gray-600;
}

.chrome-picker {
  @apply absolute right-0;
}